<template>
  <v-container>
    <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <v-card-title class="d-flex justify-space-around">
        <h1 class="font-weight-medium text-center">
          Reporte de archivos adjuntos
        </h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            class="d-flex align-center text-center checkBox-origin"
            cols="12"
            sm="2"
            md="2"
          >
            <v-checkbox
              v-model="origin"
              label="Desde el origen"
              color="indigo"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="3" md="3">
            <span
              class="
                text-subtitle-2
                col-lg-12 col-md-12 col-sm-12 col-xs-12
                pt-0
                pb-0
              "
              :style="{ color: 'initial !important' }"
              >Rango de fechas</span
            >

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
              <v-menu
                v-model="date_range.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
                :disabled="origin"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_range.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  scrollable
                  v-model="date_range.value"
                  range
                  class="pa-0"
                  locale="es"
                >
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="2" md="2">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
              &nbsp;
            </div>

            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pa-0"
            >
              <v-btn class="light-blue darken-1 white--text" @click="getItems">
                consultar
                <v-icon dark> &nbsp; mdi-magnify </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="array_table"
          item-key="_id"
          class="my-5"
        >
          <template v-slot:top>
            <v-toolbar flat class="d-flex justify-content-end">
              <v-divider v-if="false" class="mx-4" inset vertical></v-divider>
              <v-btn color="green darken-2" dark @click="create_file('excel')">
                Excel &nbsp;
                <v-icon dark> mdi-file-excel </v-icon>
              </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />
  </v-container>
</template>

<script>
import _ from "lodash";
import ModalForm from "../modal/ModalForm.vue";

export default {
  components: {
    ModalForm,
  },
  data() {
    return {
      origin: true,
      loader: {
        estado: false,
        message: "",
      },
      date_range: {
        estado: false,
        value: null,
      },
      headers: [
        { text: "Rango de fechas", value: "range" },
        { text: "Espacio en uso", value: "size" },
      ],
      array_table: [],
      data_impresion: {
        header: [
          {
            name: "Rango de fechas",
            pointer: "range",
            filterButton: false,
          },
          {
            name: "Espacio en uso",
            pointer: "size",
            filterButton: false,
          },
        ],
      },
    };
  },
  watch: {
    origin: function (val) {
      this.date_range.value = null;
    },
  },
  methods: {
    getItems() {
      if (!this.origin && !this.date_range.value) {
        alert("Por favor seleccionar el rango de fechas.");
        return;
      }

      let date_ini,
        date_fin = null;

      if (!this.origin) {
        let date = this.date_range.value;

        if (date.length == 1) {
          date.push(date[0]);
        }

        [date_ini, date_fin] = date;
      }

      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.loader.estado = true;
      let uri = "/docs/attached-files/" + String(this.$store.state.company);

      this.axios
        .post(uri, {
          date_ini,
          date_fin,
          origin: this.origin,
        })
        .then((res) => {
          console.log(res.data.data);
          let size = 0;

          for (const item of res.data.data) {
            size += item.totalSize;
          }

          this.array_table = [
            {
              range: this.origin
                ? "Desde el origen"
                : `${date_ini} - ${date_fin}`,
              size: this.formatBytes(size),
            },
          ];

          this.loader.estado = false;
        })
        .catch((error) => {
          console.log("Error get data: ", error);
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },
    async create_file(type = "excel") {
      this.data_impresion.content = _.cloneDeep(this.array_table);

      let datos = {
        tipo: type,
        datos: this.data_impresion,
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${Math.ceil(parseFloat(bytes / Math.pow(k, i)))} ${sizes[i]}`;
    },
  },
};
</script>

<style lang="css">
.checkBox-origin label.v-label {
  margin-bottom: 0px !important;
}
</style>